export const environment = {
  name: 'demo',
  production: false,
  version: '1.3.3',
  apiBaseUrl: 'https://mudlrwwyapyotpd.easyvax.org/api',
  apiLocalStorageErrorKey: 'vax-demo', // update localStorage to include this key with a value of * to force API errors for testing
  apiKey: '>$ZkT?Jb7bT>9iQW', // API key for backbone,
  recaptchaKey: '6LeRoQ8pAAAAABUHLjEtzM7LG-df0jVZ7rMswd8i',
  recaptchaAction: 'reserve',
};
